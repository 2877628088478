
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBXY0YUA6vixhrqI6oh4EI_u10Nt8mWt9w",
  authDomain: "ball-horizons.firebaseapp.com",
  projectId: "ball-horizons",
  storageBucket: "ball-horizons.appspot.com",
  messagingSenderId: "708915557820",
  appId: "1:708915557820:web:9475bf7cfb5d1eabd2e7b9",
  measurementId: "G-Z6G679LHED"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);