import React from 'react';

const PeopleInfo = [
    {
        id: "semih",
        name: "Semih Aktas",
        img: "../images/student/semih.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé sur la conception d'une expérience contenant un capteur de luminosité."
    },
    {
        id: "lucasb",
        name: "Lucas Badoil",
        img: "../images/student/lucasb.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé sur la conception d'expérience où l'une contient un capteur de température et l'autre un capteur de pression."
    },
    {
        id: "etan",
        name: "Etan Fargier",
        img: "../images/student/etan.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé sur la conception d'une expérience contenant un d'un capteur de pression."
    },
    {
        id: "nathanael",
        name: "Nathanaël Maréchet",
        img: "../images/student/nathanael.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé sur la conception de l'expérience de la caméra permettant une prise d'image tout au long du vol de la nacelle, j'ai également créée le site web de Ball'Horizons."
    },
    {
        id: "bastien",
        name: "Bastien Massacrier",
        img: "../images/student/bastien.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé sur la conception d'une expérience contenant un accéléromètre."
    },
    {
        id: "lucasp",
        name: "Lucas Pichon",
        img: "../images/student/lucasp.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé à ..."
    },
    {
        id: "aymeric",
        name: "Aymeric Pallandre",
        img: "../images/student/aymeric.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé sur la conception d'une expérience contenant un d'un capteur d'humidité."
    },
    {
        id: "anael",
        name: "Anaël Pichun--Matton",
        img: "../images/student/anael.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé à la conception de la nacelle ainsi qu'à la réalisation de celle-ci."
    },
    {
        id: "niels",
        name: "Niels Ruaud",
        img: "../images/student/niels.png",
        about: "Je suis un élève suivant la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai travaillé à ..."
    },
    {
        id: "serigne",
        name: "Serigne MBaye",
        img: "../images/student/serigne.png",
        about: "Je suis un professeur de la spécialité Sciences de l'ingénieur au Lycée des Horizons.",
        work: "J'ai encadré les élèves sur la conception de la nacelle et des expériences."
    
    }
]

export const PeoplePage = ({student}: {student: string}) => {
    const indexInfo = PeopleInfo.findIndex((students) => students.id === student);
    console.log(indexInfo);

    const imgClass = "flex h-[73.5vw] w-full lg:w-[47.5vw] lg:h-[35.625vw] flex-none items-center justify-center rounded-lg group-hover:bg-gray-500 justify-center rounded-lg overflow-hidden";
    const subtitleClass = 'text-2xl mb-2 my-3';
    const paragraphClass = 'ml-5 text-justify';

    return (
        <div className='flex flex-col items-center'>
            <h1 className='text-3xl m-5'><u>{PeopleInfo[indexInfo].name}</u></h1>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-x-3 items-center'>
                { indexInfo === 3 ? 
                    <div className={imgClass}>
                        <div className='bg-cover bg-center h-full w-full rounded-2xl' style={{backgroundImage: `url(${PeopleInfo[indexInfo].img})`, backgroundPositionY: '25%'}}></div>
                    </div> :
                    <div className={imgClass}>
                        <div className='bg-cover bg-center h-full w-full rounded-2xl' style={{backgroundImage: `url(${PeopleInfo[indexInfo].img})`}}></div>
                    </div>
                }
                <div>
                    <h2 className={subtitleClass}>A propos de moi :</h2>
                    <p className={paragraphClass}>{PeopleInfo[indexInfo].about}</p>
                    <h2 className={subtitleClass}>J'ai travaillé sur :</h2>
                    <p className={paragraphClass}>{PeopleInfo[indexInfo].work}</p>
                </div>
            </div>
        </div>
    );
};