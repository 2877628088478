import { NavLink } from "react-router-dom"

export const PageNotFound = () => {
    return (
        <div className="font-mono text-center">
            <h1 className="text-[25vh]">404</h1>
            <h3 className="text-[7.5vh] md:text-[10vh] lg:text-[12vh]">Page Inconnue</h3>
            <p className="text-[3vh] mb-7">La page à laquelle vous voulez accédez n'existe pas</p>
            <NavLink to='/' className='rounded-full p-[2vh] border-white border'>Retour a l'acceuil</NavLink>
        </div>
    )
}