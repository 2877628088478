import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { NavLink } from 'react-router-dom'

const student = [
  { name: 'Semih Aktas', description: 'A propos de Semih Aktas', href: '/students/semih', image: '../images/student/semih.png'},
  { name: 'Lucas Badoil', description: 'A propos de Lucas Badoil', href: '/students/lucasb', image: '../images/student/lucasb.png'},
  { name: 'Etan Fargier', description: 'A propos de Etan Fargier', href: '/students/etan', image: '../images/student/etan.png'},
  { name: 'Nathanaël Maréchet', description: 'A propos de Nathanaël Maréchet', href: '/students/nathanael', image: '../images/student/nathanael.png'},
  { name: 'Bastien Massacrier', description: 'A propos de Bastien Massacrier', href: '/students/bastien', image: '../images/student/bastien.png'},
  { name: 'Lucas Paget', description: 'A propos de Lucas Paget', href: '/students/lucasp', image: '../images/student/lucasp.png'},
  { name: 'Aymeric Pallandre', description: 'A propos de Aymeric Pallandre', href: '/students/aymeric', image: '../images/student/aymeric.png'},
  { name: 'Anaël Pichun--Matton', description: 'A propos de Anaël Pichun--Matton', href: '/students/anael', image: '../images/student/anael.png'},
  { name: 'Niels Ruaud', description: 'A propos de Niels Ruaud', href: '/students/niels', image: '../images/student/niels.png'},
  { name: 'Serigne Mbaye', description: 'A propos de Serigne Mbaye', href: '/teacher/serigne', image: '../images/student/serigne.png'},
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const Header = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <header className="bg-gray-400 rounded-3xl mx-5 shadow-xl">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <NavLink to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Ball'Horizons</span>
                        <img className="h-8 w-auto" src="../images/logo-nobg.png" alt="" />
                    </NavLink>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Ouvrir le Menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                    <Popover className="relative">
                        <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            <NavLink to="/collaborator" onTouchEnd={() => setMobileMenuOpen(false)}>
                                Collaborateurs
                            </NavLink>
                            <ChevronDownIcon className="h-5 w-5 flex-none text-black rotate-180 hover:rotate-0" aria-hidden="true" />
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-2xl overflow-hidden rounded-3xl bg-black shadow-lg ring-1 ring-gray-900/5 text-white">
                                <div className="p-4 grid grid-flow-row grid-cols-2 gap-x-4">
                                    {student.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-500"
                                        >
                                            <div className="flex h-24 w-20 flex-none items-center justify-center rounded-lg group-hover:bg-gray-500">
                                                <div className='bg-cover bg-center h-full w-full rounded-2xl' style={{backgroundImage: `url(${item.image})`}}></div>
                                            </div>
                                            <div className="flex-auto">
                                                <NavLink to={item.href} className="block font-semibold">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </NavLink>
                                                <p className="mt-1 text-gray-400">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                    <NavLink to="/project" className="text-sm font-semibold leading-6 text-gray-900">
                        Le projet
                    </NavLink>
                    <NavLink to="/articles" className="text-sm font-semibold leading-6 text-gray-900">
                        Articles
                    </NavLink>
                    <NavLink to="/launchnacelle" className="text-sm font-semibold leading-6 text-gray-900">
                        Lancement de la nacelle
                    </NavLink>
                </Popover.Group>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div> 
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                    <NavLink to="/" className="-m-1.5 p-1.5" onClick={() => setMobileMenuOpen(false)}>
                        <span className="sr-only">Ball'Horizons</span>
                        <img
                            className="h-8 w-auto"
                            src="../images/logo-nobg.png"
                            alt="logo"
                        />
                    </NavLink>
                    <button
                        type="button"
                        className="-m-2.5 rounded-md p-2.5 text-gray-300"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6">
                            <Disclosure as="div" className="-mx-3">
                            {({ open }) => (
                                <>
                                <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-600">
                                    <NavLink to="/collaborator">
                                        Collaborateur
                                    </NavLink>
                                    <ChevronDownIcon
                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                    aria-hidden="true"
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="mt-2 space-y-2">
                                    {[...student].map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="a"
                                        href={item.href}
                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-300 hover:bg-gray-600"
                                    >
                                        <div className="flex items-center gap-x-3 h-22">
                                            <div className='h-20 w-20'>
                                                <div className='bg-cover bg-center h-20 w-16 rounded-2xl ' style={{backgroundImage: `url(${item.image})`}}></div>
                                            </div>
                                            <div className="h-full w-full flex-none items-center justify-center rounded-lg">{item.name}</div>
                                        </div>
                                    </Disclosure.Button>
                                    ))}
                                </Disclosure.Panel>
                                </>
                            )}
                            </Disclosure>
                            <NavLink to="/project" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-600">
                                Le projet
                            </NavLink>
                            <NavLink to="/articles" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-600">
                                Articles
                            </NavLink>
                            <NavLink to="/launchnacelle" className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-300 hover:bg-gray-600">
                                Lancement de la nacelle
                            </NavLink>
                        </div>
                    </div>
                </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}