import React from 'react';
import { NavLink } from 'react-router-dom';

const Prev = [
    {
        date: "12 Mai 2024",
        launchDate: "16 Mai 2024",
        launchTime: "12h00",
        burstHeight: "30km",
        speed: "5m/s",
        img: "../images/prev/predic12may12h30km.png"
    },
    {
        date: "12 Mai 2024",
        launchDate: "16 Mai 2024",
        launchTime: "12h00",
        burstHeight: "25km",
        speed: "5m/s",
        img: "../images/prev/predic12may12h25km.png"
    },
    {
        date: "12 Mai 2024",
        launchDate: "16 Mai 2024",
        launchTime: "12h00",
        burstHeight: "20km",
        speed: "5m/s",
        img: "../images/prev/predic12may12h20km.png"
    },
    {
        date: "12 Mai 2024",
        launchDate: "16 Mai 2024",
        launchTime: "12h30",
        burstHeight: "30km",
        speed: "5m/s",
        img: "../images/prev/predic12may12.5h30km.png"
    },
    {
        date: "12 Mai 2024",
        launchDate: "16 Mai 2024",
        launchTime: "12h30",
        burstHeight: "25km",
        speed: "5m/s",
        img: "../images/prev/predic12may12.5h25km.png"
    },
    {
        date: "12 Mai 2024",
        launchDate: "16 Mai 2024",
        launchTime: "12h30",
        burstHeight: "20km",
        speed: "5m/s",
        img: "../images/prev/predic12may12.5h20km.png"
    }
]

export const Project = () => {
    return (
        <div className="flex flex-col items-center justify-center content-center">
            <h1 className="text-5xl font-bold text-center my-2">Présentation du Projet</h1>
            <h3 className="text-3xl text-left w-full mb-2 ml-20">Prévision effectuée avant le vol</h3>
            
            <div className="flex flex-col">
                <h4 className="text-2xl text-left w-full mb-2">Prévision effectuée le 12 Mai 2024 en vue d'un lancée à 12h00 le 16 Mai 2024 avec une vitesse d'ascention de 5 m/s :</h4>
                <div className="w-full grid grid-cols-1 lg:grid-cols-2">
                    {
                        Prev.map((prev) => (
                            <div className="flex flex-col items-center bg-zinc-900 rounded-3xl shadow-xl p-4 m-5">
                                <h4 className="text-xl font-bold text-justify">Prévision effectuée le {prev.date}</h4>
                                <div className="w-auto h-auto grid grid-cols-1 2xl:grid-cols-2 pt-2">
                                    <div className=" items-center">
                                        <img src={prev.img} alt="" className="rounded-2xl self-center align-middle overflow-hidden w-[20vw] h-[20vw] min-w-[350px] min-h-[350px]"/>
                                    </div>
                                    <div className="rounded-2xl h-full pl-5 flex flex-col mb-5 relative">
                                        <h5 className="text-lg"><u>Paramètre de la simulation :</u></h5>
                                        <p className="">Jour de lancement : <strong>{prev.launchDate}</strong></p>
                                        <p className="">Heure de lancement : <strong>{prev.launchTime}</strong></p>
                                        <p className="">Hauteur : <strong>{prev.burstHeight}</strong></p>
                                        <p className="">Vitesse : <strong>{prev.speed}</strong></p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
            
            <h3 className="text-3xl text-left w-full mb-2 ml-20">Vol de la nacelle : </h3>
            <NavLink to="/launchnacelle" className="m-5 p-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-2xl">Voir le lancement de la nacelle</NavLink>
        </div>
    );
};