/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.js";
import { TwitchPlayer } from "react-twitch-embed";

export const LaunchNacelle = () => {
    const [componentHeight, setComponentHeight] = React.useState((9/16)*(window.innerWidth * .45));
    const [componentWidth, setComponentWidth] = React.useState(window.innerWidth * .45);
    const [height, setHeight] = React.useState(window.innerHeight);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [nacelleId, setNacelleId] = React.useState("");
    const [urlSuivi, setUrlSuivi] = React.useState('https://amateur.sondehub.org/index.html?embed=1&hidelist=1&hidegraph=1&expandgraph=0#!mt=Mapnik&qm=6&mc=45.63253,4.39146&mz=12')
    const [newsText, setNewsText] = React.useState("");
    const [redNewsText, setRedNewsText] = React.useState("");

    const fetchFirebase = async () => {
        const docSnapNacelle = await getDoc(doc(db, "nacelle", "nacelleId"));
        const dataNacelleId = docSnapNacelle.data();
        if (dataNacelleId) {
            setNacelleId(dataNacelleId["id"]);
            if (nacelleId === "" || nacelleId === undefined || nacelleId === null || nacelleId === " " || nacelleId === ".") {
                setUrlSuivi('https://amateur.sondehub.org/index.html?embed=1&hidelist=1&hidegraph=1&expandgraph=0#!mt=Mapnik&qm=6&mc=45.63253,4.39146&mz=12')
            } else {
                setUrlSuivi(`https://amateur.sondehub.org/index.html?embed=1&hidelist=1&hidegraph=1&expandgraph=0#!mt=Mapnik&qm=6&mc=45.63253,4.39146&mz=12&f=${nacelleId}`)
                console.log(urlSuivi)
            }
        }
        const docSnapNews = await getDoc(doc(db, "nacelle", "news"));
        const dataNews = docSnapNews.data();
        if (dataNews) {
            setNewsText(dataNews["text"]);
        }
        const docSnapRedNews = await getDoc(doc(db, "nacelle", "redNews"));
        const dataRedNews = docSnapRedNews.data();
        if (dataRedNews) {
            if (dataRedNews["text"] === " " || dataRedNews["text"] === "." || dataRedNews["text"] === "" || dataRedNews["text"] === undefined || dataRedNews["text"] === null) {
                setRedNewsText("");
            } else {
                setRedNewsText(dataRedNews["text"]);
            }
        }
    }

    function refreshPage() {
        window.location.reload();
    }

    const handleResize = () => {
        if (window.innerWidth < 1536) {
            if (window.innerWidth*9 <= 400) {
                setComponentWidth(400);
                setComponentHeight(400);
            } else {
                setComponentWidth(window.innerWidth * .9);
                setComponentHeight((9/16)*(window.innerWidth * .9));
            }
        } else {
            setComponentWidth(window.innerWidth * .45);
            setComponentHeight((9/16)*(window.innerWidth * .45));
        }
    };

    //&f=nomNacelle

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.innerHeight !== height && window.innerWidth === width) {
                setHeight(window.innerHeight);
            } else {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
                fetchFirebase();
            }
        });
        handleResize();
        fetchFirebase();
    });

    useEffect(() => {
        if (window.innerWidth < 1536) {
            if (window.innerWidth*9 <= 400) {
                setComponentWidth(400);
                setComponentHeight((9/16)*400);
            } else {
                setComponentWidth(window.innerWidth * .9);
                setComponentHeight((9/16)*(window.innerWidth * .9));
            }
        } else {
            setComponentWidth(window.innerWidth * .45);
            setComponentHeight((9/16)*(window.innerWidth * .45));
        }

        setTimeout(() => {
            handleResize();
        }, 50);
    }, []);

    return (
        <div className="font-mono text-center">
            <h3 className="mb-2 text-2xl">{newsText}</h3>
            <h5>Le lancement sera normallement filmé et si possible en live.</h5>
            <p className="text-2xl text-red-500 m-2 mb-0">{redNewsText}</p>
            <div className="m-5 mt-2.5 mx-auto justify-center grid 2xl:grid-cols-2">
                <div className="m-5 mx-auto flex justify-center rounded-2xl overflow-hidden">
                    <TwitchPlayer 
                        channel="creeperfarm"
                        height={componentHeight}
                        width={componentWidth}
                        allowFullscreen={true}
                        autoplay={true}
                    />
                </div>

                <iframe
                    name="sondehub_iframe"
                    height={componentHeight}
                    width={componentWidth}
                    src={urlSuivi}
                    className="rounded-2xl m-5"
                />
            </div>
            <button className="m-5 p-2 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-2xl" onClick={refreshPage}>Recharger le suivi de la nacelle</button>
        </div>
    )
}