import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { Header } from './components/Header';
import { Collaborator } from './pages/students/PeopleShow';
import { Project } from './pages/Project';
import { Footer } from './components/Footer';
import { Articles } from './pages/articles/Article';
import { PageNotFound } from './pages/PageNotFound';
import { LaunchNacelle } from './pages/LaunchNacelle';
import { PeoplePage } from './pages/students/PeoplePage';

function App() {
  return (
    <div className='min-h-screen'>
      <div className='p-[1vh]'></div>
      <Header/>
      <div className='m-[2vh] min-h-[78.5vh]'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/collaborator" element={<Collaborator />} />
          <Route path="/students/semih" element={<PeoplePage student='semih'/>} />
          <Route path="/students/lucasb" element={<PeoplePage student='lucasb' />} />
          <Route path="/students/etan" element={<PeoplePage student='etan' />} />
          <Route path="/students/nathanael" element={<PeoplePage student='nathanael' />} />
          <Route path="/students/bastien" element={<PeoplePage student='bastien' />} />
          <Route path="/students/lucasp" element={<PeoplePage student='lucasp' />} />
          <Route path="/students/aymeric" element={<PeoplePage student='aymeric' />} />
          <Route path="/students/anael" element={<PeoplePage student='anael' />} />
          <Route path="/students/niels" element={<PeoplePage student='niels' />} />
          <Route path="/teacher/serigne" element={<PeoplePage student='serigne' />} />
          <Route path="/project" element={<Project />} />
          <Route path="/launchnacelle" element={<LaunchNacelle />} />
          <Route path="/articles" element={<Articles />} />
          <Route path='*' element={<PageNotFound />}/>
        </Routes>
      </div>
      <Footer/>
    </div>
  );
}

export default App;
