import React from 'react'

export const Articles = () => {
    return (
        <div>
            <h1 className="text-center text-4xl">Cette page est actuellement en développement</h1>
            {
                /*<h1 className="text-5xl font-bold">Project</h1>
            <div className="flex flex-col items-center w-5/12 bg-gray-300 rounded-2xl shadow-xl p-4 m-5">
                <h4 className="text-xl font-bold text-justify">Ball Horizon</h4>
                <div className="w-auto h-auto flex flex-row pt-2">
                    <div className="w-1/2 items-center">
                        <img src="../images/group.jpg" alt="" className="rounded-2xl self-center align-middle"/>
                    </div>
                    <div className="rounded-2xl w-1/2 h-full pl-5 flex flex-col mb-5 relative">
                        <p className="h-full">Item desc</p>
                        <div className="h-7 w-auto flex flex-row items-center rt-2">
                            <img src="../images/student/anael.png" alt="" className="h-7 w-6 rounded-2xl mr-2"/>
                            <p>Anaël Pichun--Matton</p>
                        </div>
                    </div>
                </div>
            </div>*/
            }
        </div>
    )
}