import { NavLink } from "react-router-dom";

const students = [
    { name: 'Semih Aktas', image_src: '../../images/student/semih.png', href: '/students/semih', work_on: ["Programmation pour l'usage d'un capteur de lumière", ] },
    { name: 'Lucas Badoil', image_src: '../../images/student/lucasb.png', href: '/students/lucasb', work_on: ["Programmation pour l'usage d'une termistance",] },
    { name: 'Etan Fargier', image_src: '../../images/student/etan.png', href: '/students/etan', work_on: ["Programmation pour l'usage d'un capteur de pression",] },
    { name: 'Nathanaël Maréchet', image_src: '../../images/student/nathanael.png', href: '/students/nathanael', work_on: ["Prise d'image avec une caméra", "Amélioration de qualité d'image", 'Stockage des images', 'Développement du site Web'] },
    { name: 'Bastien Massacrier', image_src: '../../images/student/bastien.png', href: '/students/bastien', work_on: ["Programmation pour l'usage d'un accéléromètre",] },
    { name: 'Aymeric Pallandre', image_src: '../../images/student/aymeric.png', href: '/students/aymeric', work_on: ["Programmation pour l'usage d'un capteur d'humidité", 'Tressage de câble'] },
    { name: 'Lucas Paget', image_src: '../../images/student/lucasp.png', href: '/students/lucasp', work_on: ['Conception de la nacelle', "Prise d'image avec une caméra", "Calcul de l'autonomie de la nacelle"] },
    { name: 'Anaël Pichun--Matton', image_src: '../../images/student/anael.png', href: '/students/anael', work_on: ['Structure et Design'] },
    { name: 'Niels Ruaud', image_src: '../../images/student/niels.png', href: '/students/niels', work_on: ['Aide polyvalente au bon déroullement des travails'] },
    { name: 'Serigne Mbaye', image_src: '../../images/student/serigne.png', href: '/teacher/serigne', work_on: ['Supervision des Élèves'] },
  ]

export const Collaborator = () => {
    return (
        <div className="m-5 flex flex-col items-center justify-center">
            <h1 className="text-5xl font-bold text-center">Les personnes qui ont travaillé sur le projet</h1>
            <div className="grid grid-flow-cols gap-4 box-borderborder-solid pt-10 pb-12 gap-x-6 center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                {students.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex rounded-lg p-5 text-sm leading-6 object-top w-auto h-auto shadow-xl shadow-gray-900"
                    >
                        <div className="w-[25vh]">
                            <NavLink to={item.href} className="block text-center font-bold pb-4 w-full">
                                {item.name}
                                <div className='rounded-2xl w-[20vh] h-[26.666vh] bg-cover bg-center hover:bg-[lenght:15vh_20vh] m-auto' style={{backgroundImage: `url(${item.image_src})`}}></div>
                                
                                <span className="absolute inset-0" />
                            </NavLink>
                            <p className="text-center">
                                {item.work_on.map((work) => (
                                    <span key={work}>{work}<br/></span>
                                ))}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};