import React from "react";

export const Home = () => {
    return (
        <div>
            <section className="flex flex-col items-center justify-center py-24">
                <h1 className="text-5xl font-bold text-center">Ball'Horizons</h1>
                <p className="text-justify">Ball'Horizons est un site web qui décrit le projet d'envoyer un ballon à plus de 20 kilomètres de hauteur avec ce ballon une nacelle qui contiendra des expériences. Ce site vas ainsi expliquer ce projet ainsi que d'expliquer la conception des expériences et de la nacelle.</p>
            </section>
            <div className="w-full grid grid-rows-2 lg:grid-rows-1 lg:grid-cols-2">
                <div className="m-5 mt-0 rounded-2xl p-5 grid xl:grid-cols-2 bg-white bg-opacity-5">
                    <div className="flex flex-col items-center justify-center mb-2">
                        <img src="images/logo-nobg.png" alt="logo" className="h-16 xl:h-24 w-auto"/>
                        <h2 className="text-center font-bold text-black text-3xl xl:text-4xl 2xl:text-5xl" style={{textShadow: "0px 0px 10px white"}}>Ball'Horizons</h2>
                    </div>
                    <div className="flex flex-row items-center justify-center bg-opacity-35 bg-white rounded-md p-2">
                        <p className="text-justify text-black xl:text-xl 2xl:text-2xl" style={{textShadow: "0px 0px 10px white"}}>Ball'Horizons est un site web qui décrit le projet d'envoyer un ballon à plus de 20 kilomètres de hauteur avec ce ballon une nacelle qui contiendra des expériences. Ce site vas ainsi expliquer ce projet ainsi que d'expliqué la conception des expériences et de la nacelle.</p>
                    </div>
                </div>
                <div className="rounded-2xl m-5 mt-0 w-full bg-contain bg-fixed bg-top" style={{background: "url(./images/group.jpg)", backgroundSize: "100%"}}>
                </div>
            </div>
        </div>
    );
};